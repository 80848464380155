import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUrlValid } from '../../../utils/UrlUtils';
import { Input } from '../form-control/Input';
import { LinkInfo } from './FileInfo';
import { FileUploadType } from './FileuploadType';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../modal/variants/StandardModal';

type LinkModalProps = {
  open: boolean;
  file?: LinkInfo | null;

  onCancel: () => void;
  onSave: (files: LinkInfo) => void;
};

const LinkModal: FC<LinkModalProps> = (props) => {
  const { t } = useTranslation('common');
  const { open, file, onCancel, onSave } = props;
  const [linkUrl, setLinkUrl] = useState<string>(file?.url || '');
  const [linkName, setLinkName] = useState<string>(file?.name || '');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [internalFile, setInternalFile] = useState<LinkInfo>(file || { name: '', type: FileUploadType.Link });
  const [invalidUrl, setInvalidUrl] = useState<string | undefined>();

  useEffect(() => {
    setLinkName(file?.name || '');
    setLinkUrl(file?.url || '');
    setIsEditing(!!file);
  }, [file, open]);

  useEffect(() => {
    setInvalidUrl(!isUrlValid(linkUrl, true) ? t('file-upload.errors.invalid-url') : undefined);
  }, [linkUrl, t]);

  const onCancelClick = () => {
    setLinkName('');
    setLinkUrl('');
    setInvalidUrl(undefined);
    onCancel && onCancel();
  };

  const onSaveClicked = (): void => {
    if (internalFile) {
      internalFile.name = linkName || linkUrl;
      internalFile.url = linkUrl;
      internalFile.type = FileUploadType.Link;
      setInternalFile(internalFile);
      onSave(internalFile);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <ModalContext.Provider value={{ open: open, onClose: onCancel, modalWidth: 'w-2/5' }}>
      <StandardModal
        title={!isEditing ? t('link-modal.heading.upload') : t('link-modal.heading.edit')}
        subTitle={t('link-modal.sub-heading')}
        cancelButtonTitle={t('file-upload.buttons.cancel')}
        confirmButtonTitle={t('file-upload.buttons.save')}
        onCancelClick={onCancelClick}
        onConfirmClick={onSaveClicked}
        confirmDisabled={!!invalidUrl}
      >
        <Input
          placeholder={t('link-modal.name-input.placeholder')}
          label={t('link-modal.name-input.label')}
          value={linkName}
          onChange={(event) => {
            setLinkName(event.target.value);
          }}
        />
        <Input
          placeholder={t('link-modal.url-input.placeholder')}
          label={t('link-modal.url-input.label')}
          value={linkUrl}
          error={linkUrl && invalidUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
        />
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default LinkModal;
