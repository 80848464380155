import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import FileService from '../../../../services/FileService';
import { FileInfo, LinkInfo } from '../../../shared/file-upload/FileInfo';
import FileInfoCard from '../../../shared/file-upload/FileInfoCard';
import { FileUploadType } from '../../../shared/file-upload/FileuploadType';
import FileViewer from '../../../shared/file-upload/FileViewer';
import LinkModal from '../../../shared/file-upload/LinkModal';
import SingleFileModal from '../../../shared/file-upload/SingleFileModal';
import Button, { ButtonSize, ButtonType } from '../../../shared/form-control/Button';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

export interface FileOrLinkUploadResponse {
  type: FileUploadType;
  fileInfo: FileInfo | LinkInfo | null;
}

const FileOrLinkUploadAction: FC<ActionBaseProps<FileOrLinkUploadResponse>> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { onAnswer, readOnly, currentAction } = useFormAction(props);

  const [internalFile, setInternalFile] = useState<FileInfo | null>((response?.fileInfo as FileInfo) || null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);

  const onInternalFileChanged = (f: FileInfo | null): void => {
    if (!f && internalFile && internalFile.type !== FileUploadType.Link) {
      const fileId = internalFile.id;
      FileService.deleteFile(fileId);
    }

    setInternalFile(f as FileInfo);
    onAnswer(f ? { type: FileUploadType.File, fileInfo: f } : null);
    onModalClose();
    setShowLinkModal(false);
  };

  const onLinkChanged = (link: LinkInfo | null): void => {
    setInternalFile(link as FileInfo);
    onAnswer(link ? { type: FileUploadType.Link, fileInfo: link } : null);
    setShowLinkModal(false);
  };

  useEffect(() => {
    response?.fileInfo && setInternalFile(response?.fileInfo as FileInfo);
  }, [response?.fileInfo]);

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onViewerClose = () => {
    setViewerOpen(false);
  };

  return (
    <div data-cy="file-or-link-action">
      <ActionTitleDescription required={required} {...data} />
      <div className="flex items-center gap-4">
        {!response && (
          <>
            <Button onClick={() => setModalOpen(true)} disabled={readOnly} type={ButtonType.PRIMARY} size={ButtonSize.S} data-cy="upload-document">
              {t('file-upload.buttons.upload-doc')}
            </Button>
            {t('file-upload.or')}
            <Button onClick={() => setShowLinkModal(true)} disabled={readOnly} type={ButtonType.PRIMARY} size={ButtonSize.S} data-cy="upload-link">
              {t('file-upload.buttons.upload-link')}
            </Button>
          </>
        )}
      </div>

      {internalFile && (
        <div aria-labelledby={`question-title-${currentAction.id}`} aria-describedby={`question-description-${currentAction.id}`}>
          <FileInfoCard
            file={internalFile}
            onCancel={() => onInternalFileChanged(null)}
            viewOnly={readOnly}
            onFileEdit={() => {
              internalFile.type === FileUploadType.Link ? setShowLinkModal(true) : setModalOpen(true);
            }}
          />
        </div>
      )}
      <SingleFileModal open={modalOpen} onSave={onInternalFileChanged} onCancel={onModalClose} file={internalFile} />
      <LinkModal open={showLinkModal} onSave={onLinkChanged} onCancel={() => setShowLinkModal(false)} file={internalFile} />
      {internalFile && <FileViewer open={viewerOpen} file={internalFile} onComplete={onViewerClose} />}
    </div>
  );
};

export default FileOrLinkUploadAction;
